import { useEffect } from "react"
import CarListContainer from "../../components/CarListContainer/CarListContainer"
import { useParams } from "react-router";
import { useState } from "react";
import LoadingPage from "../../components/Loading/LoadingPage";
import PaginationComponent from "../../components/Pagination/Pagination";
import { IoIosArrowBack } from 'react-icons/io';
import SmallButton from "../../components/Buttons/SmallButton";
import { useNavigate } from "react-router-dom";


export default function Catalogo(props) {
    const [carList, setCarList] = useState([])
    const [loading, setLoading] = useState(false)
    const { customUrl } = useParams();
    const [currentPage, setCurrentPage] = useState('1');
    const navigate = useNavigate()

    const baseUrl = process.env.REACT_APP_API_URL
    useEffect(() => {
        window.scrollTo(0, 0)
        const getDealer = async () => {
            const response = await fetch(`${baseUrl}/api/Dealer/GetCarsByDealerUrl?dealerUrl=${customUrl}`)
            return response.json()
        }

        if (customUrl) {
            setLoading(true)
            const res = getDealer().then(res => setCarList(res))
            setLoading(false)
        } else {
            setLoading(false)
        }
    }, [])

    const pageChanged = async (page) => {
        setCurrentPage(page);
        // await getForSaleCarList(activeFilters, page, currentOrder);
    }

    if (loading) { return <LoadingPage /> }

    return <div className="w-full sm:mt-11 text-start p-4">
        <div className="mb-2 w-fit flex justify-center items-center" onClick={() => {
            navigate(`/vendedor/${customUrl}`)
        }}><IoIosArrowBack fontSize={20} /><SmallButton> Volver</SmallButton></div>
        <h1>Catálogo</h1>
        <CarListContainer carList={carList} />
        <PaginationComponent pageChanged={pageChanged} />
    </div>
}